import React, { Component } from 'react'
import Link from '../utils/link'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <p className='footer__copy'>© Copyright I&D Group 2025</p>
          <Link to='https://atollon.com.au?utm_source=idgroup&utm_medium=website&utm_campaign=referrer&utm_term=services' title='I&D Group' className='footer__credit'>
            Site Credit
          </Link>
        </div>
      </footer>
    )
  }
}

export default Footer
